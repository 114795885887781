import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {
  IKeyboardSelect, OperatorStatusAndDuration, OperatorStatusNew,
  RemoteWorkRequestStatus, UserModelNew
} from '../../models';
import {
  BalanceStatisticDataModel,
  BalanceStatisticMetaModel,
  BalanceStatisticPeriod,
  balanceStatisticPeriodToOperatorAccountPeriod
} from '../../models/balance.models';
import {map, pluck, skipWhile, switchMap, takeWhile, tap} from 'rxjs/operators';
import { BalanceApiService } from '../../services/balance-api.service';
import { OperatorApiService, RemoteWorkService, UserApiService } from '../../services';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import {CreateTinDialogComponent, PhoneNumberVerificationDialogComponent} from '../../components';
import {HandleEventService, StateService} from '../../../services';
import {Observable, of} from 'rxjs';
import { AuthService } from '../../../auth/services';
import {MyServicesModel} from '../../models/my-services.model';
import {MyServicesApiService} from '../../services/my-services.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {MatSelectChange} from '@angular/material/select';
import {MatSlideToggleChange} from "@angular/material/slide-toggle";
import {LocalStorageService} from "ngx-webstorage";
import {ChristmasService} from "@global-services/christmas.service";
import {TariffOperatorInfoInterface} from "../../models/dispatcher.models";
import {ICallLine, ICategory} from "../../../models/global-data.interfaces";
import {GlobalDataService} from "../../../services/global-data.service";
import {
  ChangePassengerFieldModalComponent
} from "../../../passengers/components/change-passenger-field-modal/change-passenger-field-modal.component";

@UntilDestroy()
@Component({
  selector: 'utax-cabinet-main',
  templateUrl: './cabinet-main.component.html',
  styleUrls: ['./cabinet-main.component.scss']
})
export class CabinetMainComponent implements OnInit {

  readonly balanceStatisticPeriod = BalanceStatisticPeriod;
  readonly remoteWorkRequestStatus = RemoteWorkRequestStatus;
  readonly operatorStatus = OperatorStatusNew;


  selectedBalanceStatisticPeriod = BalanceStatisticPeriod.CURRENT;

  user: UserModelNew;
  services: MyServicesModel[];
  balanceStatistic: BalanceStatisticDataModel;
  balanceStatisticMeta: BalanceStatisticMetaModel;
  workStatistic: OperatorStatusAndDuration[];
  workStatisticPaused: number;
  workStatisticTalking: number;
  workStatisticFree: number;
  callsMissedCount: number;
  keyboardLayouts: IKeyboardSelect[] = [
    {name: 'UTAX_CURRENT_HOTKEY_SET', value: 'old'},
    {name: 'UTAX_NEW_HOTKEY_SET', value: 'new'}
  ];
  searchAddressMethods: IKeyboardSelect[] = [
    {name: 'UTAX_CURRENT_SEARCH_METHOD_SET', value: 'old'},
    {name: 'UTAX_NEW_SEARCH_METHOD_SET', value: 'new'}
  ];
  selectedKeyboard$: Observable<string>;
  selectedIsShowBalanceInDisp$: Observable<string>;
  selectedSearchAddressMethod$: Observable<string>;


  authUser$: Observable<any>;
  operatorTariffInfo$: Observable<TariffOperatorInfoInterface>;
  operatorCategory: ICategory;
  currentCostPerCall: number;
  currentActiveScaleIndex: number;
  viewSupportBlock: boolean;
  private queuesLines: (ICallLine | { id: string; type: string })[];
  public queuesLinesSelected: string[];
  private tariff;
  public user$: Observable<any>;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private userApiService: UserApiService,
    private balanceApiService: BalanceApiService,
    public operatorApiService: OperatorApiService,
    private translateService: TranslateService,
    public remoteWorkService: RemoteWorkService,
    private matDialog: MatDialog,
    private handleEventService: HandleEventService,
    private myServices: MyServicesApiService,
    private authService: AuthService,
    public globalDataService: GlobalDataService,
    private localStorageService: LocalStorageService,
    private state: StateService,
    public christmasService: ChristmasService
  ) {}

  ngOnInit(): void {
    this.selectedKeyboard$ = this.globalDataService.isNewKeyboardLayout$
      .pipe(
        map((value) => value ? 'new' : 'old'),
        untilDestroyed(this)
      );
    this.selectedIsShowBalanceInDisp$ = this.globalDataService.isShowBalanceInDisp$
      .pipe(
        map((value) => value ? 'show' : 'hide'),
        untilDestroyed(this)
      );
    this.authUser$ = this.authService.getUser().pipe(
      switchMap((user) => user ? of(user) : this.authService.syncUser())
    );
    this.user$ =  this.state.store
      .pipe(
        skipWhile((store) => !store?.user),
        pluck('user'),
        pluck('data'));
    this.authService.getUser().subscribe((user) => console.log(user));
    this.userApiService.user$
      .subscribe(user => {
        if (user) {
          this.user = user;
          this.getTaxiServices();
          this.updateBalanceStatistic();
          this.changeDetectorRef.detectChanges();
        }
      });
    this.selectedSearchAddressMethod$ = this.globalDataService.globalState$
      .pipe(
        map(() => 'new'),
        untilDestroyed(this)
      );
    this.operatorTariffInfo$ = this.operatorApiService.getOperatorTariffInfo().pipe(
        tap((tariff) => {
          this.tariff = tariff;
          const categories = this.globalDataService.globalCategories$.value;
          this.operatorCategory =  categories.find(category => category.id === tariff.categoryId);
          const sortedTariffScales = tariff?.tariffPlan?.scales.sort((a, b) => a.numberOfCalls - b.numberOfCalls);
          if (!tariff?.tariffPlan?.scales.length) {
            this.currentCostPerCall = 0;
          } else {
            if (sortedTariffScales.every((s) => s.numberOfCalls < tariff.currentCallCount)) {
              this.currentCostPerCall = sortedTariffScales.find((s, index) => {
                if (s.id === tariff.currentTariffPlanScaleId) {
                  this.currentActiveScaleIndex = index;
                  return true;
                }
                return false;
              }).amount;
              this.viewSupportBlock = true;
            } else if (sortedTariffScales.every((s) => s.numberOfCalls > tariff.currentCallCount)) {
              this.currentCostPerCall = sortedTariffScales[0].amount;
              this.currentActiveScaleIndex = 0;
            } else {
              this.currentCostPerCall = sortedTariffScales.find((s, index) => {
                if (s.id === tariff.currentTariffPlanScaleId) {
                  this.currentActiveScaleIndex = index;
                  return true;
                }
                return false;
              }).amount;
            }
          }
          this.queuesLines = [
            ...this.globalDataService.globalCallLines$.value.filter((line) => line.useAsQueueType).map(el => ({id: el.id, type: 'UTAX_CALL_LINE_' + el.type})),
            ...this.globalDataService.globalTransferList$.value.filter((queue) => queue.useAsQueueType).map(el => ({id: el.id, type: el.name}))
          ];
          this.queuesLinesSelected = this.queuesLines.filter((el) => this.tariff?.tariffPlan?.queueIds.includes(el.id) || this.tariff?.tariffPlan?.callLineIds.includes(el.id)).map(el => el.type);
          this.changeDetectorRef.detectChanges();
        }),
        untilDestroyed(this)
      );
    this.globalDataService.globalCategories$
      .pipe(
        tap(() => {
          this.queuesLines = [
            ...this.globalDataService.globalCallLines$.value.filter((line) => line.useAsQueueType).map(el => ({id: el.id, type: 'UTAX_CALL_LINE_' + el.type})),
            ...this.globalDataService.globalTransferList$.value.filter((queue) => queue.useAsQueueType).map(el => ({id: el.id, type: el.name}))
          ];
          this.queuesLinesSelected = this.queuesLines.filter((el) => this.tariff?.tariffPlan?.queueIds.includes(el.id) || this.tariff?.tariffPlan?.callLineIds.includes(el.id)).map(el => el.type);
          this.changeDetectorRef.detectChanges();
        }),
        untilDestroyed(this)
      )
      .subscribe();
    this.globalDataService.globalTransferList$
      .pipe(
        tap(() => {
          this.queuesLines = [
            ...this.globalDataService.globalCallLines$.value.filter((line) => line.useAsQueueType).map(el => ({id: el.id, type: 'UTAX_CALL_LINE_' + el.type})),
            ...this.globalDataService.globalTransferList$.value.filter((queue) => queue.useAsQueueType).map(el => ({id: el.id, type: el.name}))
          ];
          this.queuesLinesSelected = this.queuesLines.filter((el) => this.tariff?.tariffPlan?.queueIds.includes(el.id) || this.tariff?.tariffPlan?.callLineIds.includes(el.id)).map(el => el.type);
          this.changeDetectorRef.detectChanges();
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }


  updateStatistic(): void {
    this.callsMissedCount = 0;
    this.workStatisticFree = 0;
    this.workStatisticTalking = 0;
    this.workStatisticPaused = 0;
    this.operatorApiService.getAccountStatisticNew(balanceStatisticPeriodToOperatorAccountPeriod(this.selectedBalanceStatisticPeriod))
      .pipe(
        tap((item) => this.callsMissedCount = item?.missedCallsCount),
        switchMap(() => this.operatorApiService.getAccountWorkStatisticNew(balanceStatisticPeriodToOperatorAccountPeriod(this.selectedBalanceStatisticPeriod)))
      )
      .subscribe(workStatistic => {
        if (workStatistic.length > 0) {
          workStatistic.forEach((option) => {
            if (option?.status === this.operatorStatus.FREE) {
              this.workStatisticFree = option.seconds;
            } else if (option?.status === this.operatorStatus.TALKING) {
              this.workStatisticTalking = option.seconds;
            } else if (option?.status === this.operatorStatus.PAUSED) {
              this.workStatisticPaused = option.seconds;
            }
          });
        }
        this.changeDetectorRef.detectChanges();
      });
  }
  updateBalanceStatistic(): void {
    const operatorAccountPeriod = balanceStatisticPeriodToOperatorAccountPeriod(this.selectedBalanceStatisticPeriod);

    this.balanceApiService.getStatistic(this.selectedBalanceStatisticPeriod)
      .subscribe(balanceStatistic => {
        this.balanceStatistic = balanceStatistic.data;
        this.balanceStatisticMeta = balanceStatistic.meta;
        this.changeDetectorRef.detectChanges();
      });

    // this.operatorApiService.getAccountWorkStatisticNew(operatorAccountPeriod)
    //   .subscribe(workStatistic => {
    //     this.workStatistic = workStatistic;
    //     this.changeDetectorRef.detectChanges();
    //   });
    this.updateStatistic();

  }

  sortQueuePredicate(a, b) {
    if (a?.isActive > b?.isActive) {
      return -1;
    }
    if (a?.isActive === b?.isActive) {
      return 0;
    }
    if (a?.isActive < b?.isActive) {
      return 1;
    }
  }

  getTaxiServices(): void {
    this.myServices.getTaxiServices().subscribe((services) => {
      this.services = services;
    });
  }

  requestPhoneNumberVerification(): void {
    const dialogRef = this.matDialog.open(PhoneNumberVerificationDialogComponent, {
      panelClass: 'phone-number-verification-dialog',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.handleEventService.openSnackBar(this.translateService.instant('PHONE_NUMBER_VERIFICATION_SUCCESS'));
      }
    });
  }

  public changeStateSetting(layout: MatSelectChange, typeSetting: 'keyboardSet' | 'searchAddressMethod' | 'showBalanceInDisp') {
    this.globalDataService.changeGlobalState({[typeSetting]: layout.value})
      .pipe(
        tap(() => {
          if ( typeSetting === 'keyboardSet' ) {
            this.globalDataService.isNewKeyboardLayout$.next(layout.value === 'new');
          } else if (typeSetting === 'showBalanceInDisp') {
            this.globalDataService.isShowBalanceInDisp$.next(layout.value === 'show');
          }
        }),
        untilDestroyed(this)
      ). subscribe();
  }

  changeValentineMood($event: MatSlideToggleChange) {
    this.localStorageService.store('valentineMood', $event.checked ? 'active' : 'inactive');
  }

  public openAddTinDialog() {
    this.matDialog.open(CreateTinDialogComponent, {
      panelClass: 'request-activate-dialog-container',
      backdropClass: 'request-dialog-backdrop',
      width: '376px',
      disableClose: true,
    }).afterClosed().subscribe((result) => {
      if (result) {
        this.handleEventService.openSnackBar(`UTAX_OPERATOR_TIN_ADD_SUCCESS`);
        this.changeDetectorRef.detectChanges();
      }
    });
  }
}
